import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Plain from 'slate-plain-serializer';
import { Value } from 'slate';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SlateEditor, { defaultValue } from '/client/app/components/common/inputs/slateEditor/slateEditor';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import Hideable from '/client/app/components/common/hideable';

import './messageForm.scss';

interface IProps {
	setComposingMessage: (composing: boolean) => void;
	sendMessage: (subject: string, messagePlaintext: string, bodyHTML: string, inReplyTo?: string) => void;
	recipient: string;
	defaultSubject?: string;
	inReplyTo?: string;
	error: string;
}

export default function MessageForm(props: IProps) {
	const { setComposingMessage, recipient, sendMessage, error, defaultSubject, inReplyTo } = props;

	const [submittingMessage, setSubmittingMessage] = useState(false);
	const [messageBody, setMessageBody] = useState(defaultValue);
	const [messageSubject, setMessageSubject] = useState(defaultSubject || '');
	const [messageSent, setMessageSent] = useState(false);

	function isValidMessage() {
		return Plain.serialize(messageBody).trim().length > 0 && messageSubject.trim().length > 0;
	}

	async function submit() {
		setSubmittingMessage(true);
		const messageHTML = htmlSerializer.serialize(messageBody);
		const messagePlaintext = Plain.serialize(messageBody);
		try {
			await sendMessage(messageSubject, messagePlaintext, messageHTML, inReplyTo);
			setMessageSent(true);
		} catch (err) {
			// Error handled elsewhere, and we always want to set submitting false
		}
		setSubmittingMessage(false);
	}

	return (
		<div className="composeMessageContainer">
			<div className="composeMessage">
				<Hideable hidden={messageSent}>
					<input className="commonInput messageInput" name="recipient" value={`to ${recipient}`} disabled />
					<input
						className="commonInput messageInput"
						value={messageSubject}
						name="subject"
						onChange={e => setMessageSubject(e.target.value)}
						placeholder="subject"
					/>
					<SlateEditor onChange={(valueJS: any, value: Value) => setMessageBody(value)} key="sendUserMessage" />
					<button
						type="button"
						disabled={!isValidMessage() || submittingMessage}
						className="standardButton messageButton"
						onClick={submit}
					>
						{submittingMessage ? 'sending...' : 'send'}
					</button>
					<button
						type="button"
						disabled={submittingMessage}
						className="standardButton messageButton"
						onClick={() => setComposingMessage(false)}
					>
						cancel
					</button>
				</Hideable>
				<Hideable hidden={!error}>
					<div className="messageFailure">{error}</div>
				</Hideable>
				<Hideable hidden={!messageSent}>
					<div className="messageSuccess">
						message sent
						<button
							type="button"
							disabled={submittingMessage}
							className="tinyButton closeButton"
							onClick={() => setComposingMessage(false)}
						>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</Hideable>
			</div>
		</div>
	);
}

MessageForm.propTypes = {
	setComposingMessage: PropTypes.func.isRequired,
	recipient: PropTypes.string.isRequired,
	error: PropTypes.string,
	defaultSubject: PropTypes.string,
	sendMessage: PropTypes.func.isRequired,
	inReplyTo: PropTypes.string,
};

MessageForm.defaultProps = {
	error: undefined,
	defaultSubject: undefined,
	inReplyTo: undefined,
};
