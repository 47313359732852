import gql from 'graphql-tag';

const GET_POST = gql`
	query($postId: String!) {
		post(_id: $postId) {
			_id
			text
			textHTML
			user {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
			media {
				_id
				path
			}
			mediaThumbnails {
				_id
				path
			}
			thumbnail {
				_id
				path
			}
			embed {
				_id
				thumbnailURL
				html
				compactHTML
			}
			tips
			sub {
				_id
				name
				actionCost
			}
			commentCount
			creationDate
			title
			url
		}
		sessionUser {
			_id
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;

export default GET_POST;
