import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Feed from './feed';
import { POSTS_BY_USER, POSTS_BY_SUBS, SUBSCRIBED_POSTS } from '/client/app/graphql/queries/posts';
import Hideable from '/client/app/components/common/hideable';
import '/client/app/styles/error.scss';
import { SESSION_SETTINGS } from '/client/app/graphql/queries/sessionSettings';
import { SET_FEED_VIEW_MODE } from '/client/app/graphql/mutations/sessionSettings';
import { SESSION_USER_WITH_ACCOUNTS } from '/client/app/graphql/queries/user';
import { SUBSCRIBE_TO_USER, UNSUBSCRIBE_FROM_USER } from '/client/app/graphql/userActions';

export default function FeedContainer(props) {
	const { communityNames, pageSize, username } = props;
	const [page, setPage] = useState(0);
	const [sortOrder, setSortOrder] = useState('best');

	const { data: sessionSettingsData } = useQuery(SESSION_SETTINGS, { errorPolicy: 'ignore' });
	const [setSessionFeedViewMode] = useMutation(SET_FEED_VIEW_MODE);
	const { data: sessionUser } = useQuery(SESSION_USER_WITH_ACCOUNTS);

	let query;
	if (username) query = POSTS_BY_USER;
	else if (communityNames && communityNames.length > 0) query = POSTS_BY_SUBS;
	else query = SUBSCRIBED_POSTS;

	const variables = username ? { username } : { subNames: communityNames };

	const { data, loading, refetch, error } = useQuery(query, {
		variables: {
			sortOrder,
			count: pageSize,
			skip: pageSize * page,
			...variables,
		},
		fetchPolicy: 'cache-and-network',
		onCompleted: appendData,
	});

	function getPostData(queryResult = data) {
		if (!queryResult) return [];
		if (username) return queryResult.postsByUser;
		if (communityNames && communityNames.length > 0) return queryResult.postsBySubs;
		return queryResult.subscribedPosts;
	}

	const [posts, setPosts] = useState(getPostData());
	const [lastEffectivePageSize, setLastEffectivePageSize] = useState(posts.length);

	function appendData({ postsByUser, postsBySubs, subscribedPosts }) {
		const postData = postsBySubs || postsByUser || subscribedPosts;
		const newPosts = posts.slice();
		const existingIds = posts.map((post) => post._id);
		postData.forEach((post) => {
			if (!existingIds.includes(post._id)) {
				newPosts.push(post);
			}
		});
		setPosts(newPosts);
		setLastEffectivePageSize(postData.length);
	}

	async function loadMore() {
		if (lastEffectivePageSize < pageSize) await refetch();
		else setPage(page + 1);
	}

	function setSortOrderAndReload(newSortOrder) {
		setPosts([]);
		setPage(0);
		setSortOrder(newSortOrder);
	}

	async function setFeedViewMode(feedViewMode) {
		if (sessionSettingsData) return setSessionFeedViewMode({ variables: { feedViewMode } });
		return null;
	}

	const [subscribe] = useMutation(SUBSCRIBE_TO_USER);
	const [unsubscribe] = useMutation(UNSUBSCRIBE_FROM_USER);

	async function subscribeToUser() {
		await subscribe({
			variables: {
				username,
			},
		});
	}

	async function unsubscribeFromUser() {
		await unsubscribe({
			variables: {
				username,
			},
		});
	}

	return (
		<>
			<Hideable hidden={!!error}>
				<Feed
					setSortOrder={setSortOrderAndReload}
					loadMore={loadMore}
					loading={loading}
					posts={posts.length ? posts : getPostData()}
					sessionFeedViewMode={sessionSettingsData?.sessionSettings.feedViewMode}
					setSessionFeedViewMode={setFeedViewMode}
					username={username}
					v
					sessionUser={sessionUser?.sessionUser}
					subscribeToUser={subscribeToUser}
					unsubscribeFromUser={unsubscribeFromUser}
				/>
			</Hideable>
			<Hideable hidden={!error}>
				<span className="errorLabelMedium">{error?.message}</span>
			</Hideable>
		</>
	);
}

FeedContainer.propTypes = {
	communityNames: PropTypes.arrayOf(PropTypes.string),
	username: PropTypes.string,
	pageSize: PropTypes.number,
};

FeedContainer.defaultProps = {
	pageSize: 20,
	username: undefined,
	communityNames: [],
};
