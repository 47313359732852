import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import FeedItem from './feedItem';
import CardFeedItem from '../cardFeedItem/cardFeedItem';
import { SAVE_POST, TIP_POST, UNSAVE_POST } from '../../../graphql/userActions';
import GET_POST from '/client/app/components/feed/feedItem/postQuery';
import { GET_COMMENTS } from '/client/app/views/post/postComments';
import useCachedQuery from '/client/app/graphql/useCachedQuery';
import Loading from '/client/app/components/loading/loading';

export default function FeedItemContainer(props) {
	const { postId, feedType, ssr } = props;
	const client = useApolloClient();
	const { data, error, loading } = useCachedQuery(GET_POST, { variables: { postId }, ssr });
	const [tipPost] = useMutation(TIP_POST);
	const [savePost, { loading: saving }] = useMutation(SAVE_POST);
	const [unsavePost, { loading: unsaving }] = useMutation(UNSAVE_POST);
	const userActions = {
		tipPost,
		savePost,
		unsavePost,
	};

	async function queryFullPostData() {
		await client.query({ query: GET_COMMENTS, variables: { postId } });
	}

	function feedOrError() {
		if (error) return <span className="error">{error.message}</span>;
		return feedType === 'List' ? (
			<FeedItem
				postData={data}
				queryFullPostData={queryFullPostData}
				modifyingSaveState={saving || unsaving}
				userActions={userActions}
			/>
		) : (
			<CardFeedItem
				postData={data}
				compact={feedType === 'SmallCard'}
				queryFullPostData={queryFullPostData}
				userActions={userActions}
			/>
		);
	}

	if (loading)
		return feedType === 'List' ? (
			[<Loading height="4em" width="70px" />, <Loading height="4em" />]
		) : (
			<Loading width="100%" height="6em" />
		);
	return feedOrError();
}
