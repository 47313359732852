import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faBars, faImage, faEye } from '@fortawesome/free-solid-svg-icons';

import FeedItemContainer from '/client/app/components/feed/feedItem/feedItemContainer';
import Hideable from '/client/app/components/common/hideable';
import RadioButtonGroup from '/client/app/components/common/inputs/radioButton/radioButtonGroup';
import Loading from '/client/app/components/loading/loading';
import PlaceholderWidget from '/client/app/components/placeholderWidget/placeholderWidget';
import MessageFormContainer from '/client/app/views/messages/messageForm/messageFormContainer';

require('/client/app/components/feed/feed.scss');

export default function Feed(props) {
	const {
		loading,
		posts,
		ssr,
		loadMore,
		setSortOrder,
		sessionFeedViewMode,
		setSessionFeedViewMode,
		username,
		sessionUser,
		unsubscribeFromUser,
		subscribeToUser,
	} = props;
	const [localFeedType, setLocalFeedType] = useState(sessionFeedViewMode || 'List');
	const [showingModQueue, setShowingModQueue] = useState(false);
	const [composingMessage, setComposingMessage] = useState(false);
	const [modifyingSubscription, setModifyingSubscription] = useState(false);

	const isSubscribed =
		username && !!sessionUser?.subscriptions?.users?.find((u) => u.usernameLowercase === username.toLowerCase());

	async function toggleUserSubscription() {
		setModifyingSubscription(true);
		if (isSubscribed) await unsubscribeFromUser();
		else await subscribeToUser();
		setModifyingSubscription(false);
	}

	function setFeedType(feedType) {
		// If we have a session then we should set the feed type in session settings
		if (sessionFeedViewMode) setSessionFeedViewMode(feedType);
		setLocalFeedType(feedType);
	}

	function feedItemsOrLoading() {
		if (loading && !posts.length) {
			if (localFeedType === 'Card' || localFeedType === 'SmallCard') return <Loading width="100%" height="6em" />;
			return [<Loading height="4em" width="70px" />, <Loading height="4em" />];
		}
		return posts.map((post) => <FeedItemContainer postId={post._id} ssr={ssr} feedType={localFeedType} key={post._id} />);
	}

	function selectView(viewType) {
		if (viewType === 'moderation queue') {
			setShowingModQueue(true);
		} else {
			setSortOrder(viewType);
			setShowingModQueue(false);
		}
	}

	const feedSortOptions = [
		{ id: 'hot', value: 'hot', label: 'hot' },
		{ id: 'new', value: 'new', label: 'new' },
		{ id: 'best', value: 'best', label: 'best' },
		{ id: 'modQueue', value: 'moderation queue', label: <FontAwesomeIcon title="moderation queue" icon={faEye} /> },
	];

	const feedViewOptions = [
		{ id: 'List', value: 'List', label: <FontAwesomeIcon icon={faList} /> },
		{ id: 'SmallCard', value: 'SmallCard', label: <FontAwesomeIcon icon={faBars} /> },
		{ id: 'Card', value: 'Card', label: <FontAwesomeIcon icon={faImage} /> },
	];

	return (
		<div className={`feedContainer feedContainer${localFeedType.replace('SmallCard', 'Card')}`}>
			<div className="feedViewControls">
				<Hideable hidden={!username}>
					<div className="userControls">
						<button
							type="button"
							disabled={composingMessage || !sessionUser}
							className="standardButton"
							onClick={() => setComposingMessage(true)}
						>
							send message
						</button>
						<button type="button" disabled className="standardButton">
							block
						</button>
						<button
							type="button"
							disabled={!sessionUser || modifyingSubscription}
							onClick={toggleUserSubscription}
							className="standardButton"
						>
							{isSubscribed ? 'unsubscribe' : 'subscribe'}
						</button>
						<Hideable hidden={!composingMessage}>
							{() => (
								<div className="messageWrapper">
									<MessageFormContainer recipient={username} setComposingMessage={setComposingMessage} />
								</div>
							)}
						</Hideable>
					</div>
				</Hideable>
				<div className="feedViewSettings">
					<RadioButtonGroup
						name="feedViewModeToggle"
						onChange={(e) => setFeedType(e.target.value)}
						buttons
						horizontal
						defaultOptionId={localFeedType}
						options={feedViewOptions}
					/>
					<RadioButtonGroup
						name="sort"
						onChange={(e) => selectView(e.target.value)}
						buttons
						horizontal
						defaultOptionId="hot"
						options={feedSortOptions}
					/>
				</div>
			</div>
			<Hideable hidden={!showingModQueue}>
				<PlaceholderWidget text="moderator queue for this feed will go here" />
			</Hideable>
			<Hideable hidden={showingModQueue}>
				<div className="feed">{feedItemsOrLoading()}</div>
				<Hideable hidden={loading || posts.length > 0}>
					<div className="nothingThere">looks like there aren&apos;t any posts yet</div>
				</Hideable>
				<div className="loadMoreContainer">
					<button type="button" disabled={loading} onClick={loadMore} className="tinyButton loadMoreButton">
						{!loading && posts.length === 0 ? 'reload' : 'load more'}
					</button>
				</div>
			</Hideable>
		</div>
	);
}

Feed.propTypes = {
	loadMore: PropTypes.func.isRequired,
	setSortOrder: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	ssr: PropTypes.bool,
	posts: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string,
		})
	),
	sessionFeedViewMode: PropTypes.string,
	setSessionFeedViewMode: PropTypes.func.isRequired,
	username: PropTypes.string,
	sessionUser: PropTypes.shape({
		subscriptions: PropTypes.shape({
			users: PropTypes.arrayOf(
				PropTypes.shape({
					usernameLowercase: PropTypes.string.isRequired,
				})
			),
		}),
	}),
	subscribeToUser: PropTypes.func.isRequired,
	unsubscribeFromUser: PropTypes.func.isRequired,
};

Feed.defaultProps = {
	loading: false,
	ssr: true,
	posts: [],
	sessionFeedViewMode: undefined,
	username: undefined,
	sessionUser: undefined,
};
