import gql from 'graphql-tag';

export const SEND_MESSAGE = gql`
	mutation sendMessage($to: String!, $subject: String!, $body: String!, $bodyHTML: String!, $inReplyTo: String) {
		sendMessage(to: $to, subject: $subject, body: $body, bodyHTML: $bodyHTML, inReplyTo: $inReplyTo) {
			_id
		}
	}
`;

export const SET_READ = gql`
	mutation setRead($_id: String!, $read: Boolean!) {
		setRead(_id: $_id, read: $read) {
			_id
		}
	}
`;

export const DELETE_MESSAGE = gql`
	mutation deleteMessage($_id: String!) {
		deleteMessage(_id: $_id) {
			_id
			deleted
		}
	}
`;
