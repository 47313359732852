import gql from 'graphql-tag';

export const POSTS_BY_USER = gql`
	query posts($username: String!, $sortOrder: String, $count: Int, $skip: Int) {
		postsByUser(username: $username, count: $count, skip: $skip, sortOrder: $sortOrder) {
			_id
			media {
				_id
				path
			}
			mediaThumbnails {
				_id
				path
			}
			thumbnail {
				_id
				path
			}
			embed {
				_id
				thumbnailURL
				html
				compactHTML
			}
			text
			textHTML
			tips
			title
			creationDate
			commentCount
			url
			urlTitle
			user {
				_id
				username
			}
			sub {
				_id
				actionCost
				name
				creator {
					_id
					username
				}
				moderators {
					_id
					username
				}
				owner {
					_id
					username
				}
				description
				sidebarText
				submissionPageText
				title
				subscribers
				creationDate
			}
		}
		sessionUser {
			_id
			username
			email
			account {
				_id
				balance
			}
			escrowAccount {
				_id
				balance
			}
			totalInEscrow
			memberOf {
				_id
				name
				builtin
			}
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;

export const POSTS_BY_SUBS = gql`
	query($subNames: [String!]!, $sortOrder: String, $count: Int, $skip: Int) {
		postsBySubs(subNames: $subNames, count: $count, skip: $skip, sortOrder: $sortOrder) {
			_id
			media {
				_id
				path
			}
			mediaThumbnails {
				_id
				path
			}
			thumbnail {
				_id
				path
			}
			embed {
				_id
				thumbnailURL
				html
				compactHTML
			}
			text
			textHTML
			tips
			title
			creationDate
			commentCount
			url
			urlTitle
			user {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
			sub {
				_id
				actionCost
				name
				title
				creator {
					_id
					username
				}
				moderators {
					_id
					username
				}
				owner {
					_id
					username
				}
				description
				sidebarText
				subscribers
				creationDate
				submissionPageText
			}
		}
		sessionUser {
			_id
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;

export const SUBSCRIBED_POSTS = gql`
	query($sortOrder: String, $count: Int, $skip: Int) {
		subscribedPosts(count: $count, skip: $skip, sortOrder: $sortOrder) {
			_id
			media {
				_id
				path
			}
			mediaThumbnails {
				_id
				path
			}
			thumbnail {
				_id
				path
			}
			embed {
				_id
				thumbnailURL
				html
				compactHTML
			}
			text
			textHTML
			tips
			title
			creationDate
			commentCount
			url
			urlTitle
			user {
				_id
				username
				memberOf {
					_id
					name
					builtin
				}
			}
			sub {
				_id
				actionCost
				name
				title
				creator {
					_id
					username
				}
				moderators {
					_id
					username
				}
				owner {
					_id
					username
				}
				description
				sidebarText
				subscribers
				creationDate
				submissionPageText
			}
		}
		sessionUser {
			_id
			savedItems {
				posts {
					_id
				}
			}
		}
	}
`;
