import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { SEND_MESSAGE } from '/client/app/graphql/mutations/messages';

import MessageForm from './messageForm';

interface IProps {
	setComposingMessage: (composing: boolean) => void;
	recipient: string;
	defaultSubject?: string;
	inReplyTo?: string;
}

export default function MessageFormContainer(props: IProps) {
	const [sendMessage, { error: sendError }] = useMutation(SEND_MESSAGE);
	const error = sendError ? sendError.message : undefined;

	const { setComposingMessage, recipient, defaultSubject } = props;

	async function sendMessageAdapter(subject: String, bodyPlaintext: String, bodyHTML: String, inReplyTo?: String) {
		return sendMessage({
			variables: {
				to: recipient,
				subject,
				body: bodyPlaintext,
				bodyHTML,
				inReplyTo,
			},
		});
	}

	const { inReplyTo } = props;

	return (
		<MessageForm
			setComposingMessage={setComposingMessage}
			recipient={recipient}
			sendMessage={sendMessageAdapter}
			error={error}
			defaultSubject={defaultSubject}
			inReplyTo={inReplyTo}
		/>
	);
}

MessageFormContainer.propTypes = {
	setComposingMessage: PropTypes.func.isRequired,
	recipient: PropTypes.string.isRequired,
	defaultSubject: PropTypes.string,
	inReplyTo: PropTypes.string,
};

MessageFormContainer.defaultProps = {
	defaultSubject: undefined,
	inReplyTo: undefined,
};
