import React, { useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '/client/app/components/link';
import '/client/app/styles/controls.scss';
import ArmableButton from '../../common/armableButton/armableButton';
import getTimeDelta from '../../../util/dateUtils';
import Hideable from '../../common/hideable';
import formatCurrency from '../../../util/formatCurrency';
import CommunityRuleViolationReportFormContainer from '/client/app/components/communityRuleViolationReportForm/communityRuleViolationReportFormContainer';
import UserLink from '/client/app/components/userLink/userLink';

require('./feedItem.scss');
require('lazysizes');

export default function FeedItem(props) {
	const {
		queryFullPostData,
		modifyingSaveState,
		postData: {
			sessionUser,
			post: {
				_id,
				user: { username, memberOf },
				thumbnail: { path: thumbnail },
				media,
				embed,
				tips,
				sub: { name: sub, actionCost, _id: communityId },
				commentCount,
				creationDate,
				title,
				url,
			},
		},
		userActions: { tipPost, savePost, unsavePost },
	} = props;

	const [reporting, setReporting] = useState(false);

	let isLoggedIn = false;
	let savedPosts;
	let isSavedPost = false;

	if (sessionUser) {
		savedPosts = props.postData.sessionUser.savedItems.posts;
		isSavedPost = savedPosts.find(post => post._id === _id);
		isLoggedIn = true;
	}

	function handleSavePost() {
		if (isSavedPost) unsavePost({ variables: { postId: _id } });
		else savePost({ variables: { postId: _id } });

		isSavedPost = !isSavedPost;
	}

	function getLinkTarget() {
		if (url) return url;
		if (media && media.length > 0) return media[0].path;
		return `/s/${sub}/comments/${_id}`;
	}

	const saveButtonClasses = ['textButton'];
	if (modifyingSaveState) saveButtonClasses.push('pulsing');

	return [
		<div className="feedItemLeft" key={2}>
			<div className="feedItemThumbContainer">
				<div className="imageWrapper">
					<Link target="_blank" href={getLinkTarget()}>
						<img
							data-sizes="auto"
							className="feedItemThumb lazyload"
							alt="Post thumbnail"
							data-src={embed?.thumbnailURL || thumbnail}
						/>
					</Link>
					<Hideable hidden={!media || media.length === 0}>
						<span className="feedItemExpandoContainer">
							<span className="feedItemExpando" />
						</span>
					</Hideable>
				</div>
				<div className="feedItemValue">{formatCurrency(tips, true)}</div>
			</div>
		</div>,
		<div className="feedItemRight" key={3}>
			<Link target="_blank" className="feedItemTitle" href={getLinkTarget()}>
				{title}
			</Link>
			<div className="feedItemDetails">
				<UserLink username={username} isAdmin={memberOf.find(group => group.name === 'Site Admins' && group.builtin)} />{' '}
				<FontAwesomeIcon className="feedItemPostedToArrow" icon={faArrowRight} /> <Link href={`/s/${sub}`}>{sub}</Link>
			</div>
			<div className="feedItemDetails">posted {getTimeDelta(new Date(creationDate))}</div>
			<div className="feedItemLinks">
				<ArmableButton tiny onConfirm={() => tipPost({ variables: { postId: _id } })} disabled={!isLoggedIn}>
					tip <span className="actionCost">{formatCurrency(actionCost)}</span>
				</ArmableButton>
				<Link onMouseOver={queryFullPostData} onFocus={queryFullPostData} href={`/s/${sub}/comments/${_id}`}>
					{commentCount} {commentCount === 0 || commentCount > 1 ? 'comments' : 'comment'}
				</Link>
				<button
					type="button"
					className={saveButtonClasses.join(' ')}
					disabled={modifyingSaveState || !isLoggedIn}
					onClick={handleSavePost}
				>
					{isSavedPost ? 'unsave' : 'save'}
				</button>
				<button type="button" disabled={!isLoggedIn} onClick={() => setReporting(!reporting)} className="textButton">
					{reporting ? 'cancel' : 'report'}
				</button>
			</div>
			<Hideable hidden={!reporting}>
				<CommunityRuleViolationReportFormContainer
					onSaveOrCancel={() => setReporting(false)}
					communityId={communityId}
					objectId={_id}
					objectType="Post"
				/>
			</Hideable>
		</div>,
	];
}
